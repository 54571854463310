const ArtworksData = [
  {
    name: "Bud",
    year: "2019.",
    medium: "Mixed Media.",
    dimension: '24" x 25" x 24".',
    collectionOfPics: [
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/Bud/categoryPic.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/Bud/categoryPic.jpg"),
        isCategoryPic: true,
      },
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/Bud/1.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/Bud/1.jpg"),
        isCategoryPic: false,
      },
    ],
  },
  {
    name: "Forma",
    year: "2021.",
    medium: "Wood.",
    dimension: '12" x 9" x 12".',
    collectionOfPics: [
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/Forma/categoryPic.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/Forma/categoryPic.jpg"),
        isCategoryPic: true,
      },
    ],
  },
  {
    name: "Ordinary, Yet Original",
    year: "2021.",
    medium: "Wood Joinery; Levitation; Lamination; Mixed Media.",
    dimension: '28" x 18" x 36".',
    collectionOfPics: [
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/OrdinaryYetOriginal/categoryPic.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/OrdinaryYetOriginal/categoryPic.jpg"),
        isCategoryPic: true,
      },
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/OrdinaryYetOriginal/1.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/OrdinaryYetOriginal/1.jpg"),
        isCategoryPic: false,
      },
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/OrdinaryYetOriginal/2.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/OrdinaryYetOriginal/2.jpg"),
        isCategoryPic: false,
      },
    ],
  },
  {
    name: "Beginning-Ending",
    year: "2021.",
    medium: "Wood Joinery; Augmented Reality; Mixed Media.",
    dimension: '27" x 24" x 75".',
    collectionOfPics: [
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/Beginning-Ending/categoryPic.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/Beginning-Ending/categoryPic.jpg"),
        isCategoryPic: true,
      },
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/Beginning-Ending/1.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/Beginning-Ending/1.jpg"),
        isCategoryPic: false,
      },
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/Beginning-Ending/2.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/Beginning-Ending/2.jpg"),
        isCategoryPic: false,
      },
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/Beginning-Ending/3.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/Beginning-Ending/3.jpg"),
        isCategoryPic: false,
      },
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/Beginning-Ending/4.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/Beginning-Ending/4.jpg"),
        isCategoryPic: false,
      },
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/Beginning-Ending/5.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/Beginning-Ending/5.jpg"),
        isCategoryPic: false,
      },
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/Beginning-Ending/6.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/Beginning-Ending/6.jpg"),
        isCategoryPic: false,
      },
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/Beginning-Ending/7.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/Beginning-Ending/7.jpg"),
        isCategoryPic: false,
      },
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/Beginning-Ending/8.jpg"),
        original: "https://vimeo.com/696814828",
        isCategoryPic: false,
      },
    ],
  },
  {
    name: "Reminiscence",
    year: "2021.",
    medium:
      "Wood Joinery; Marble Crystal; Resin Crystal Beads Casting; Mixed Media (Wood-Sound).",
    dimension: '41" x 15" x 41".',
    collectionOfPics: [
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/Reminiscence/categoryPic.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/Reminiscence/categoryPic.jpg"),
        isCategoryPic: true,
      },
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/Reminiscence/1.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/Reminiscence/1.jpg"),
        isCategoryPic: false,
      },
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/Reminiscence/2.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/Reminiscence/2.jpg"),
        isCategoryPic: false,
      },
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/Reminiscence/3.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/Reminiscence/3.jpg"),
        isCategoryPic: false,
      },
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/Reminiscence/4.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/Reminiscence/4.jpg"),
        isCategoryPic: false,
      },
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/Reminiscence/5.jpg"),
        original: "https://vimeo.com/696814963",
        isCategoryPic: false,
      },
    ],
  },
  {
    name: "Harmonizing",
    year: "2021.",
    medium: "Wood Joinery; Levitation; Mixed Media.",
    dimension: '30" x 26" x 15".',

    collectionOfPics: [
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/Harmonizing/categoryPic.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/Harmonizing/categoryPic.jpg"),
        isCategoryPic: true,
      },
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/Harmonizing/1.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/Harmonizing/1.jpg"),
        isCategoryPic: false,
      },
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/Harmonizing/2.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/Harmonizing/2.jpg"),
        isCategoryPic: false,
      },
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/Harmonizing/3.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/Harmonizing/3.jpg"),
        isCategoryPic: false,
      },
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/Harmonizing/4.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/Harmonizing/4.jpg"),
        isCategoryPic: false,
      },
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/Harmonizing/5.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/Harmonizing/5.jpg"),
        isCategoryPic: false,
      },
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/Harmonizing/6.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/Harmonizing/6.jpg"),
        isCategoryPic: false,
      },
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/Harmonizing/7.jpg"),
        original: "https://vimeo.com/696815019",
        isCategoryPic: false,
      },
    ],
  },
  {
    name: "Why?",
    year: "2022.",
    medium: "Wood Joinery; Lamination; Mixed Media.",
    dimension: '18" x 20" x 34".',
    collectionOfPics: [
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/Why/categoryPic.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/Why/categoryPic.jpg"),
        isCategoryPic: true,
      },
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/Why/1.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/Why/1.jpg"),
        isCategoryPic: false,
      },
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/Why/2.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/Why/2.jpg"),
        isCategoryPic: false,
      },
    ],
  },
  {
    name: "What?",
    year: "2022.",
    medium: "Wood Joinery; Blue Lapis Crystal; Lamination; Mixed Media.",
    dimension: '12.5" x 1.5" x 17".',
    collectionOfPics: [
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/What/categoryPic.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/What/categoryPic.jpg"),
        isCategoryPic: true,
      },
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/What/1.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/What/1.jpg"),
        isCategoryPic: false,
      },
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/What/2.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/What/2.jpg"),
        isCategoryPic: false,
      },
    ],
  },
  {
    name: "Where?",
    year: "2022.",
    medium: "Wood Joinery; Laser Cutting on Plexiglass and Wood; Mixed Media.",
    dimension: '11" x 1.5" x 11" and 10" x 1.5" x 10".',
    collectionOfPics: [
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/Where/categoryPic.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/Where/categoryPic.jpg"),
        isCategoryPic: true,
      },
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/Where/1.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/Where/1.jpg"),
        isCategoryPic: false,
      },
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/Where/2.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/Where/2.jpg"),
        isCategoryPic: false,
      },
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/Where/3.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/Where/3.jpg"),
        isCategoryPic: false,
      },
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/Where/4.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/Where/4.jpg"),
        isCategoryPic: false,
      },
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/Where/5.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/Where/5.jpg"),
        isCategoryPic: false,
      },
    ],
  },
  {
    name: "How?",
    year: "2022.",
    medium: "Wood Joinery; Resin Casting; Print Technique.",
    dimension: '20" x 20" x 25".',
    collectionOfPics: [
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/How/categoryPic.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/How/categoryPic.jpg"),
        isCategoryPic: true,
      },
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/How/1.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/How/1.jpg"),
        isCategoryPic: false,
      },
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/How/2.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/How/2.jpg"),
        isCategoryPic: false,
      },
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/How/3.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/How/3.jpg"),
        isCategoryPic: false,
      },
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/How/4.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/How/4.jpg"),
        isCategoryPic: false,
      },
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/How/5.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/How/5.jpg"),
        isCategoryPic: false,
      },
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/How/6.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/How/6.jpg"),
        isCategoryPic: false,
      },
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/How/7.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/How/7.jpg"),
        isCategoryPic: false,
      },
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/How/8.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/How/8.jpg"),
        isCategoryPic: false,
      },
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/How/9.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/How/9.jpg"),
        isCategoryPic: false,
      },
    ],
  },
  {
    name: "Anachronism",
    year: "2022.",
    medium: "Laminating Wood.",
    dimension: "Varied Dimensions.",
    collectionOfPics: [
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/Anachronism/categoryPic.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/Anachronism/categoryPic.jpg"),
        isCategoryPic: true,
      },
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/Anachronism/1.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/Anachronism/1.jpg"),
        isCategoryPic: false,
      },
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/Anachronism/2.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/Anachronism/2.jpg"),
        isCategoryPic: false,
      },
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/Anachronism/3.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/Anachronism/3.jpg"),
        isCategoryPic: false,
      },
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/Anachronism/4.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/Anachronism/4.jpg"),
        isCategoryPic: false,
      },
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/Anachronism/5.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/Anachronism/5.jpg"),
        isCategoryPic: false,
      },
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/Anachronism/6.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/Anachronism/6.jpg"),
        isCategoryPic: false,
      },
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/Anachronism/7.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/Anachronism/7.jpg"),
        isCategoryPic: false,
      },
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/Anachronism/8.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/Anachronism/8.jpg"),
        isCategoryPic: false,
      },
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/Anachronism/9.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/Anachronism/9.jpg"),
        isCategoryPic: false,
      },
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/Anachronism/10.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/Anachronism/10.jpg"),
        isCategoryPic: false,
      },
    ],
  },
  {
    name: "Missing Creator",
    year: "2022.",
    medium: "Printed on Laminated Joinery Wood Sculpture.",
    dimension: "Dimensions: To Be Determined.",
    collectionOfPics: [
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/MissingCreator/categoryPic.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/MissingCreator/categoryPic.jpg"),
        isCategoryPic: true,
      },
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/MissingCreator/1.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/MissingCreator/1.jpg"),
        isCategoryPic: false,
      },
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/MissingCreator/2.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/MissingCreator/2.jpg"),
        isCategoryPic: false,
      },
    ],
  },
  {
    name: "Beat the Clock",
    year: "2023.",
    medium: "Laminating Wood; Crystal Marbles; Resin.",
    dimension: '11" x 11" x 42".',
    collectionOfPics: [
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/BeatTheClock/categoryPic.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/BeatTheClock/categoryPic.jpg"),
        isCategoryPic: true,
      },
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/BeatTheClock/1.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/BeatTheClock/1.jpg"),
        isCategoryPic: false,
      },
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/BeatTheClock/2.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/BeatTheClock/2.jpg"),
        isCategoryPic: false,
      },
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/BeatTheClock/3.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/BeatTheClock/3.jpg"),
        isCategoryPic: false,
      },
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/BeatTheClock/4.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/BeatTheClock/4.jpg"),
        isCategoryPic: false,
      },
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/BeatTheClock/5.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/BeatTheClock/5.jpg"),
        isCategoryPic: false,
      },
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/BeatTheClock/6.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/BeatTheClock/6.jpg"),
        isCategoryPic: false,
      },
    ],
  },
  {
    name: "Recess",
    year: "2023.",
    medium: "Resin Casting; Laminate Wood Sculpture.",
    dimension: '9" x 6 1/2" x 3 1/8".',
    collectionOfPics: [
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/Recess/categoryPic.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/Recess/categoryPic.jpg"),
        isCategoryPic: true,
      },
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/Recess/1.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/Recess/1.jpg"),
        isCategoryPic: false,
      },
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/Recess/2.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/Recess/2.jpg"),
        isCategoryPic: false,
      },
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/Recess/3.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/Recess/3.jpg"),
        isCategoryPic: false,
      },
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/Recess/4.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/Recess/4.jpg"),
        isCategoryPic: false,
      },
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/Recess/5.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/Recess/5.jpg"),
        isCategoryPic: false,
      },
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/Recess/6.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/Recess/6.jpg"),
        isCategoryPic: false,
      },
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/Recess/7.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/Recess/7.jpg"),
        isCategoryPic: false,
      },
    ],
  },
  {
    name: "Hermit Crab",
    year: "2023.",
    medium:
      "Fabrid; Monofilament Fishing Line; Push Pins; Laminate; Kinetic Wood Sculpture.",
    dimension: '120" x 108" x 102".',
    collectionOfPics: [
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/HermitCrab/categoryPic.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/HermitCrab/categoryPic.jpg"),
        isCategoryPic: true,
      },
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/HermitCrab/1.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/HermitCrab/1.jpg"),
        isCategoryPic: false,
      },
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/HermitCrab/2.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/HermitCrab/2.jpg"),
        isCategoryPic: false,
      },
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/HermitCrab/3.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/HermitCrab/3.jpg"),
        isCategoryPic: false,
      },
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/HermitCrab/4.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/HermitCrab/4.jpg"),
        isCategoryPic: false,
      },
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/HermitCrab/5.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/HermitCrab/5.jpg"),
        isCategoryPic: false,
      },
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/HermitCrab/6.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/HermitCrab/6.jpg"),
        isCategoryPic: false,
      },
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/HermitCrab/7.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/HermitCrab/7.jpg"),
        isCategoryPic: false,
      },
    ],
  },
  {
    name: "Untitled",
    year: "2023.",
    medium: "Laminate Wood Sculpture.",
    dimension: '3" x 3" x 4.5".',
    collectionOfPics: [
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/Untitled/categoryPic.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/Untitled/categoryPic.jpg"),
        isCategoryPic: true,
      },
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/Untitled/1.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/Untitled/1.jpg"),
        isCategoryPic: false,
      },
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/Untitled/2.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/Untitled/2.jpg"),
        isCategoryPic: false,
      },
    ],
  },
  {
    name: "Pop 'n' Drop",
    year: "2023.",
    medium: "Hybrid Print on Silk; Wood Joinery; Laminate Wood Sculpture.",
    dimension: '36" x 10" x 2".',
    collectionOfPics: [
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/PopNDrop/categoryPic.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/PopNDrop/categoryPic.jpg"),
        isCategoryPic: true,
      },
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/PopNDrop/1.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/PopNDrop/1.jpg"),
        isCategoryPic: false,
      },
    ],
  },
  {
    name: "Song Hành",
    year: "2023.",
    medium: "Mixed Media.",
    dimension: '11" x 11".',
    collectionOfPics: [
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/SongHành/categoryPic.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/SongHành/categoryPic.jpg"),
        isCategoryPic: true,
      },
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/SongHành/1.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/SongHành/1.jpg"),
        isCategoryPic: false,
      },
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/SongHành/2.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/SongHành/2.jpg"),
        isCategoryPic: false,
      },
    ],
  },
  {
    name: "Bamboo Glass Ceiling",
    year: "2023.",
    medium: "Resin Casting; Yarns; Star Anise; Laminate Wood Sculpture.",
    dimension: '13" x 13" x 23 1/2".',
    collectionOfPics: [
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/BambooGlassCeiling/categoryPic.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/BambooGlassCeiling/categoryPic.jpg"),
        isCategoryPic: true,
      },
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/BambooGlassCeiling/1.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/BambooGlassCeiling/1.jpg"),
        isCategoryPic: false,
      },
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/BambooGlassCeiling/2.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/BambooGlassCeiling/2.jpg"),
        isCategoryPic: false,
      },
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/BambooGlassCeiling/3.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/BambooGlassCeiling/3.jpg"),
        isCategoryPic: false,
      },
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/BambooGlassCeiling/4.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/BambooGlassCeiling/4.jpg"),
        isCategoryPic: false,
      },
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/BambooGlassCeiling/5.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/BambooGlassCeiling/5.jpg"),
        isCategoryPic: false,
      },
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/BambooGlassCeiling/6.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/BambooGlassCeiling/6.jpg"),
        isCategoryPic: false,
      },
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/BambooGlassCeiling/7.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/BambooGlassCeiling/7.jpg"),
        isCategoryPic: false,
      },
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/BambooGlassCeiling/8.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/BambooGlassCeiling/8.jpg"),
        isCategoryPic: false,
      },
    ],
  },
  {
    name: "Vitality",
    year: "2023.",
    medium: "Mixed Media.",
    dimension: '10" x 9" x 10". 9" x 7" x 9".',
    collectionOfPics: [
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/Vitality/categoryPic.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/Vitality/categoryPic.jpg"),
        isCategoryPic: true,
      },
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/Vitality/1.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/Vitality/1.jpg"),
        isCategoryPic: false,
      },
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/Vitality/2.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/Vitality/2.jpg"),
        isCategoryPic: false,
      },
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/Vitality/3.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/Vitality/3.jpg"),
        isCategoryPic: false,
      },
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/Vitality/4.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/Vitality/4.jpg"),
        isCategoryPic: false,
      },
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/Vitality/5.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/Vitality/5.jpg"),
        isCategoryPic: false,
      },
    ],
  },
  {
    name: "Absurdity",
    year: "2024.",
    medium: "Joinery Mixed Media. Patterned Wood and Etching. Acrylic.",
    dimension: '14" x 10" x 16".',
    collectionOfPics: [
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/Absurdity/categoryPic.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/Absurdity/categoryPic.jpg"),
        isCategoryPic: true,
      },
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/Absurdity/1.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/Absurdity/1.jpg"),
        isCategoryPic: false,
      },
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/Absurdity/2.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/Absurdity/2.jpg"),
        isCategoryPic: false,
      },
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/Absurdity/3.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/Absurdity/3.jpg"),
        isCategoryPic: false,
      },
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/Absurdity/4.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/Absurdity/4.jpg"),
        isCategoryPic: false,
      },
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/Absurdity/5.jpg"),
        original: "https://vimeo.com/1034864731",
        isCategoryPic: false,
      },
    ],
  },
  {
    name: "Serenity",
    year: "2024.",
    medium: "Joinery Mixed Media. Patterned Wood and Etching. Acrylic.",
    dimension: '23" x 12" x 23".',
    collectionOfPics: [
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/Serenity/categoryPic.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/Serenity/categoryPic.jpg"),
        isCategoryPic: true,
      },
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/Serenity/1.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/Serenity/1.jpg"),
        isCategoryPic: false,
      },
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/Serenity/2.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/Serenity/2.jpg"),
        isCategoryPic: false,
      },
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/Serenity/3.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/Serenity/3.jpg"),
        isCategoryPic: false,
      },
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/Serenity/4.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/Serenity/4.jpg"),
        isCategoryPic: false,
      },
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/Serenity/5.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/Serenity/5.jpg"),
        isCategoryPic: false,
      },
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/Serenity/6.jpg"),
        original: require("../../Assets/Images/ArtImages/Originals/Serenity/6.jpg"),
        isCategoryPic: false,
      },
      {
        thumbnail: require("../../Assets/Images/ArtImages/Thumbnails/Serenity/7.jpg"),
        original: "https://vimeo.com/1034865202",
        isCategoryPic: false,
      },
    ],
  },
];

const justPictures = [];

// logic that takes the above array of gallery image data and just includes the image objects
ArtworksData.map((artwork) =>
  artwork.collectionOfPics.forEach((currentPicture, i) => {
    currentPicture["name"] = artwork.name;
    currentPicture["year"] = artwork.year;
    currentPicture["medium"] = artwork.medium;
    currentPicture["dimension"] = artwork.dimension;
    justPictures.push(currentPicture);
  })
);

export default justPictures;
